<template>
  <modal name="modal-asset-tracker" class="asset-tracker modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="reset">
    <span class="close-button" @click="hide()"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header less-margin-bottom text-lg bg-primary-50 font-serif">
      Asset Tracker
    </div>
    <div class="modal-body less-side-padding" v-bind:class="{ 'less-bottom-padding': !renderDirection }">
      <ValidationObserver ref="formAssetTracker">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12">
            <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" class="w-full h-90vh" ref="map">
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"></GmapInfoWindow>

              <GmapMarker :position="startLocation" @dragend="changeLocationStart" :options="{ draggable: true, clickable: true, label: 'A', visible: startMarkerVisible }" />
              <GmapMarker :position="endLocation" @dragend="changeLocationEnd" :options="{ draggable: true, clickable: true, label: 'B', visible: endMarkerVisible }" />

              <DirectionsRenderer v-if="renderDirection" travelMode="DRIVING" :origin="startLocation" :destination="endLocation" :waypoints="waypoints" :options="{ draggable: true }" @directions_changed="directionChanged" @error_direction="handleDirectionError" />

              <GmapPolyline :path="definedRoute" :options="{ strokeColor: '#1A8DE9', strokeOpacity: 0.8, strokeWeight: 2 }" />
              <GmapPolyline :path="trackedRoute" :options="{ strokeColor: 'red', strokeOpacity: 0.8, strokeWeight: 2 }" />
              <GmapCircle v-for="(pin, index) in waitingCircles" :key="index" :center="pin" :options="{ fillColor: 'orange', fillOpacity: 0.7, radius: 200, strokeColor: 'orange', strokeOpacity: 0, strokeWeight: 0 }" />
            </GmapMap>
          </div>
          <div class="w-full lg:w-3/12 flex flex-col px-2" v-if="renderDirection">
            <div>
              <GmapAutocomplete ref="startPlace" @place_changed="setStartPlace" :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }" placeholder="Startplats" />
            </div>
            <div class="text-center my-2 text-xs">
              <BaseIcon icon="arrow-down" />
            </div>
            <div>
              <GmapAutocomplete ref="endPlace" @place_changed="setEndPlace" :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }" placeholder="Slutplats" />
            </div>

            <div class="border-b border-secondary-400 my-5"></div>

            <div class="clear-both">
              <div class="float-left text-sm pt-1">
                Avresa kl
              </div>
              <div class="float-right">
                <date-picker v-model="departureTime" type="datetime" :lang="date_picker_lang" :key="date_picker_langKey" :open.sync="datePickerOpen" :editable="false" :clearable="false" format="YYYY-MM-DD, HH:mm" :minute-step="15" :disabled-date="disableDate" :disabled-time="disableTime" @change="handleTimeSelection"></date-picker>
              </div>
            </div>

            <div class="border-b border-secondary-400 my-5"></div>

            <div class="clear-both">
              <div class="float-left text-sm max-w-full pt-1">
                Max tillåtna stopptid (minuten)
              </div>
              <div class="float-right">
                <ValidationProvider name="Maximal väntetid" rules="required" v-slot="{ classes }">
                  <div class="input-validate" :class="classes">
                    <input type="number" v-model="maxWaitingTime" min="15" max="30" @keypress="isNumber($event)" @change="calculateExpectedArrival" class="bg-gray-100 w-20 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" />
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="clear-both mt-2">
              <div class="float-left text-sm pt-1">
                Tillåtna stopp under vägen (stopp = >10min)
              </div>
              <div class="float-right">
                <ValidationProvider name="Totala väntestopp" rules="required" v-slot="{ classes }">
                  <div class="input-validate" :class="classes">
                    <input type="number" v-model="maxStops" min="1" max="10" @keypress="isNumber($event)" @change="calculateExpectedArrival" class="bg-gray-100 w-20 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" />
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="border-b border-secondary-400 mt-5 mb-2"></div>

            <div class="clear-both">
              <div class="float-left text-sm">
                Beräknad ankomst
              </div>
              <div class="float-right text-sm font-semibold">
                {{ expectedArrival }}
              </div>
            </div>

            <div class="border-b border-secondary-400 my-3"></div>

            <div class="flex flex-wrap">
              <div class="w-full lg:w-1/6">
                <span class="text-sm">Email</span>
              </div>
              <div class="w-full lg:w-5/6 ">
                <input type="text" v-model="emailAddress" placeholder="peter@company.com, vincent@company.com" class="bg-gray-100 w-full rounded text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" />
              </div>
            </div>
            <div class="flex flex-wrap mt-2">
              <div class="w-full lg:w-1/6">
                <span class="text-sm">Phone</span>
              </div>
              <div class="w-full lg:w-5/6 ">
                <input type="text" v-model="smsNumber" @keypress="isNumber($event)" maxlength="15" placeholder="Fullständigt mobilnummer med land/riktnummer" class="bg-gray-100 w-full rounded text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" />
              </div>
            </div>

            <div class="clear-both mt-4">
              <button class="float-right text-xs text-primary-500 disabled:opacity-25" @click.prevent="copyDirectionLink()" :disabled="startPosition == null || endPosition == null" :readonly="startPosition == null || endPosition == null">
                Kopiera vägbeskrivningar
                <BaseIcon icon="share-alt" class="ml-1" />
              </button>
            </div>

            <div class="border-b border-secondary-400 mt-3 mb-1"></div>

            <div class="max-h-56 overflow-y-scroll">
              <div id="directionsPanel"></div>
            </div>
          </div>

          <div class="w-full lg:w-3/12 flex flex-col px-2" v-if="!renderDirection">
            <div class="text-sm text-center">
              {{ startAddress }}
            </div>
            <div class="text-center my-1">
              <BaseIcon icon="arrow-down" class="text-sm" />
            </div>
            <div class="text-sm text-center">
              {{ endAddress }}
            </div>

            <div class="border-b border-secondary-400 my-5"></div>

            <div class="clear-both">
              <div class="float-left text-sm">
                Avresa kl
              </div>
              <div class="float-right text-sm">
                {{ formatDate(departureTime) }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Beräknad ankomst
              </div>
              <div class="float-right text-sm">
                {{ expectedArrival }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Max tillåtna stopptid
              </div>
              <div class="float-right text-sm">
                {{ maxWaitingTime }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Tillåtna stopp under vägen (stopp = >10min)
              </div>
              <div class="float-right text-sm">
                {{ maxStops }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Email
              </div>
              <div class="float-right text-sm">
                {{ updateInfo.email_address }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Phone
              </div>
              <div class="float-right text-sm">
                {{ updateInfo.sms_number }}
              </div>
            </div>

            <div class="border-b border-secondary-400 my-5"></div>

            <div class="clear-both">
              <div class="float-left text-sm">
                Trip start
              </div>
              <div class="float-right text-sm font-bold">
                {{ tripStartTime }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Trip end
              </div>
              <div class="float-right text-sm font-bold">
                {{ tripEndTime }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Total waiting time
              </div>
              <div class="float-right text-sm font-bold">
                {{ tripTotalWaitingTime }}
              </div>
            </div>
            <div class="clear-both mt-1">
              <div class="float-left text-sm">
                Total waiting stops
              </div>
              <div class="float-right text-sm font-bold">
                {{ updateInfo.trip_state.waitingStops }}
              </div>
            </div>

            <div class="border-b border-secondary-400 my-5"></div>

            <div class="max-h-72 overflow-y-scroll">
              <div class="flex flex-col">
                <div class="text-sm font-bold mb-3">Trip Summary</div>
                <div v-for="(l, i) in updateInfo.trip_log" :key="i" @click="showTripReport(l)" class="mt-2 cursor-pointer flex flex-col">
                  <div class="clear-both">
                    <div class="float-left text-sm">
                      <BaseIcon
                        icon="dot-circle"
                        class="mr-2"
                        v-bind:class="{
                          'text-secondary-500': l.type == 'trip_start' || l.type == 'trip_end',
                          'text-yellow-400': l.type == 'waiting_start' || l.type == 'waiting_end',
                          'text-red-500': l.type == 'deviated',
                          'text-accent-500': l.type == 'on_track',
                        }"
                      />
                      <span v-if="l.type == 'trip_start'">Resan börjar</span>
                      <span v-if="l.type == 'trip_end'">Resan genomförd</span>
                      <span v-if="l.type == 'waiting_start'">Väntan började</span>
                      <span v-if="l.type == 'waiting_end'">
                        Väntande slut<span v-if="l.duration != undefined">, {{ l.duration }} min.</span>
                      </span>
                      <span v-if="l.type == 'on_track'">På rutt</span>
                      <span v-if="l.type == 'deviated'">Avvikit från rutt</span>
                    </div>
                    <div class="float-right text-sm">
                      {{ formatDate(l.time) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer" v-if="renderDirection">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide()">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-blue px-3 py-2 float-right" @click="saveAssetTrip()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 1400;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";
import { gmapApi } from "vue2-google-maps";
import DirectionsRenderer from "@/components/map/DirectionsRenderer";

export default {
  name: "AssetTracker",

  components: {
    DatePicker,
    DirectionsRenderer,
  },

  props: {
    imei_number: {
      required: true,
      default: null,
    },
    updateInfo: {
      default: null,
    },
  },

  data() {
    return {
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      datePickerOpen: false,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
      // autocomplete
      startLocation: null,
      endLocation: null,
      startMarkerVisible: true,
      endMarkerVisible: true,
      // backend
      startPosition: null,
      endPosition: null,
      departureTime: null,
      maxWaitingTime: 30,
      maxStops: 3,
      // drivingOptions: null,
      definedRoute: [],
      waypoints: [],
      savedWaypoints: [],
      totalDistance: null,
      totalDuration: null,
      startAddress: "",
      endAddress: "",
      expectedArrival: "",
      emailAddress: "",
      smsNumber: "",
      renderDirection: true,
      trackedRoute: [],
      waitingCircles: [],
      // info window
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
    };
  },

  computed: {
    google: gmapApi,
    tripStartTime() {
      if (this.updateInfo && this.updateInfo.trip_log.length > 0) {
        let l = _.find(this.updateInfo.trip_log, { type: "trip_start" });
        if (l) return this.formatDate(l.time);
      }
      return "";
    },
    tripEndTime() {
      if (this.updateInfo && this.updateInfo.trip_log.length > 0) {
        let l = _.find(this.updateInfo.trip_log, { type: "trip_end" });
        if (l) return this.formatDate(l.time);
      }
      return "";
    },
    tripTotalWaitingTime() {
      if (this.updateInfo && this.updateInfo.trip_log.length > 0) {
        let waitEndedArr = _.filter(this.updateInfo.trip_log, { type: "waiting_end" });
        if (waitEndedArr.length > 0) {
          let t = 0;
          for (let i = 0; i < waitEndedArr.length; i++) {
            let w = waitEndedArr[i];
            t += w.duration;
          }

          return t;
        }
      }
      return 0;
    },
  },

  watch: {
    // departureTime: function () {
    //   this.debounceDrivingOptions();
    // },
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  methods: {
    setStartPlace(place) {
      this.waypoints = [];
      this.startLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.focusMarker(this.startLocation);
    },

    setEndPlace(place) {
      this.waypoints = [];

      this.endLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.focusMarker(this.endLocation);
    },

    focusMarker(position) {
      this.$refs.map.$mapObject.panTo(position);
      this.$refs.map.$mapObject.setZoom(15);
    },

    async changeLocationStart(event) {
      try {
        let position = { lat: event.latLng.lat(), lng: event.latLng.lng() };

        const geocoder = new this.google.maps.Geocoder();
        let response = await geocoder.geocode({ location: position });

        this.$refs.startPlace.$refs.input.value = response.results[0].formatted_address;
      } catch (error) {
        //console.log(error);
      }
    },

    async changeLocationEnd(event) {
      try {
        let position = { lat: event.latLng.lat(), lng: event.latLng.lng() };

        const geocoder = new this.google.maps.Geocoder();
        let response = await geocoder.geocode({ location: position });

        this.$refs.endPlace.$refs.input.value = response.results[0].formatted_address;
      } catch (error) {
        //console.log(error);
      }
    },

    directionChanged(data) {
      // console.log(data,'direction changed');

      this.startMarkerVisible = false;
      this.endMarkerVisible = false;

      // todo: incase of multiple stop, calculate start, end, duration, distance. Calculate for each leg
      this.totalDuration = data.routes[0].legs[0].duration.value; // seconds
      // if(data.routes[0].legs[0].duration_in_traffic) this.totalDuration = data.routes[0].legs[0].duration_in_traffic.value;

      this.totalDistance = data.routes[0].legs[0].distance.value; // meters

      this.startPosition = { lat: data.routes[0].legs[0].start_location.lat(), lng: data.routes[0].legs[0].start_location.lng() };
      this.startAddress = data.routes[0].legs[0].start_address;

      this.endPosition = { lat: data.routes[0].legs[0].end_location.lat(), lng: data.routes[0].legs[0].end_location.lng() };
      this.endAddress = data.routes[0].legs[0].end_address;

      this.definedRoute = data.routes[0].overview_path;

      let waypoints = [];
      for (let i = 0; i < data.routes[0].legs[0].via_waypoints.length; i++) {
        let w = data.routes[0].legs[0].via_waypoints[i];
        waypoints.push({ lat: w.lat(), lng: w.lng() });
      }
      this.savedWaypoints = waypoints;

      this.calculateExpectedArrival();

      // this.$emit('routeChanged', this.definedRoute);
    },

    calculateExpectedArrival() {
      if (this.totalDuration) {
        let totalWaitingTime = parseInt(this.maxWaitingTime) * parseInt(this.maxStops);
        this.expectedArrival = this.moment(this.departureTime)
          .add(totalWaitingTime, "minutes")
          .add(this.totalDuration, "seconds")
          .format("YYYY-MM-DD, HH:mm");
      }
    },

    handleDirectionError(status) {
      if (status == "ZERO_RESULTS") {
        this.handleError(null, "Ingen direkt rutt", "top-right", 3000);
        this.startMarkerVisible = true;
        this.endMarkerVisible = true;
      }

      if (status == "INVALID_REQUEST") {
        this.handleError(null, "Avgångstid måste vara i framtiden", "top-right", 1500);
        this.checkDepartureTime();
      }
    },

    copyDirectionLink() {
      var vm = this;
      let origin = `${this.startPosition.lat},${this.startPosition.lng}`;
      let destination = `${this.endPosition.lat},${this.endPosition.lng}`;
      let waypoints = "";
      for (let i = 0; i < this.savedWaypoints.length; i++) {
        let w = this.savedWaypoints[i];
        waypoints += `${w.lat},${w.lng}|`;
      }

      let url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving&waypoints=${waypoints}`;

      const cb = navigator.clipboard;
      cb.writeText(url).then(() => vm.handleSuccess("Vägbeskrivning kopierad"));
    },

    saveAssetTrip() {
      this.$refs.formAssetTracker.validate().then((success) => {
        if (!success) {
          this.handleError(null, "Några av obligatoriska fält är tomma");
          return;
        }

        // todo: convert time to UTC

        let data = {
          imei_number: this.imei_number,
          start_position: this.startPosition,
          end_position: this.endPosition,
          start_address: this.startAddress,
          end_address: this.endAddress,
          departure_time: this.departureTime,
          waiting_limit: this.maxWaitingTime,
          waiting_stop: this.maxStops,
          defined_route: this.definedRoute,
          waypoints: this.savedWaypoints,
          total_distance: this.totalDistance,
          total_duration: this.totalDuration,
          email_address: this.emailAddress,
          sms_number: this.smsNumber,
        };

        if (this.updateInfo != null) {
          delete data.imei_number;

          this.axios
            .put(`${process.env.VUE_APP_SERVER_URL}/asset-tracker/trip/${this.updateInfo._id}/${this.imei_number}`, data)
            .then(() => {
              //eslint-disable-line
              this.$emit("routeSaved");
              this.hide();
              this.handleSuccess();
              this.reset();
            })
            .catch((error) => {
              this.handleError(error);
            });
        } else {
          this.axios
            .post(`${process.env.VUE_APP_SERVER_URL}/asset-tracker`, data)
            .then(() => {
              //eslint-disable-line
              this.$emit("routeSaved");
              this.hide();
              this.handleSuccess();
              this.reset();
            })
            .catch((error) => {
              this.handleError(error);
            });
        }
      });
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },

    disableTime(date) {
      let today = this.moment();
      today.subtract(1, "hour");
      return date < new Date(today);
    },

    handleTimeSelection(value, type) {
      // eslint-disable-line
      if (type === "minute") {
        this.datePickerOpen = false;
      }

      this.checkDepartureTime();
      this.calculateExpectedArrival();
    },

    checkDepartureTime() {
      let departureTimeMoment = this.moment(this.departureTime);
      let now = this.moment();
      if (departureTimeMoment.isBefore(now)) {
        let t = 15 - (now.minutes() % 15);
        now.add(t, "minutes");
        this.departureTime = new Date(now);
      }
    },

    initDate() {
      this.departureTime = new Date(
        this.moment()
          .add(2, "hours")
          .startOf("hour")
      );
    },

    // setDrivingOptions() {
    //   this.checkDepartureTime();
    //   this.drivingOptions = {
    //     departureTime: this.departureTime,
    //   };
    // },

    setupTripInfo() {
      var vm = this;

      if (this.updateInfo) {
        if (this.updateInfo.trip_state && this.updateInfo.trip_state.tripStarted) this.renderDirection = false;

        if (this.updateInfo.start_address) {
          this.startAddress = this.updateInfo.start_address;
          if (this.renderDirection) {
            setTimeout(() => {
              vm.$refs.startPlace.$refs.input.value = this.updateInfo.start_address;
            }, 1000);
          }
        }
        if (this.updateInfo.end_address) {
          this.endAddress = this.updateInfo.end_address;
          if (this.renderDirection) {
            setTimeout(() => {
              vm.$refs.endPlace.$refs.input.value = this.updateInfo.end_address;
            }, 1000);
          }
        }

        if (this.updateInfo.waiting_limit) this.maxWaitingTime = this.updateInfo.waiting_limit;
        if (this.updateInfo.waiting_stop) this.maxStops = this.updateInfo.waiting_stop;
        if (this.updateInfo.total_duration) this.totalDuration = this.updateInfo.total_duration;

        if (this.updateInfo.departure_time && this.updateInfo.trip_state && !this.updateInfo.trip_state.tripStarted) {
          let departTime = this.moment(this.updateInfo.departure_time);

          if (departTime.diff(this.moment(), "minutes") >= 15) this.departureTime = new Date(this.updateInfo.departure_time);
          else this.initDate();
        } else {
          this.departureTime = new Date(this.updateInfo.departure_time);
          this.calculateExpectedArrival();
        }

        if (this.updateInfo.waypoints && this.updateInfo.waypoints.length > 0) {
          setTimeout(() => {
            vm.$refs.map.$mapPromise.then(() => {
              let waypoints = [];
              for (let i = 0; i < vm.updateInfo.waypoints.length; i++) {
                let w = vm.updateInfo.waypoints[i];
                waypoints.push({
                  location: new vm.google.maps.LatLng(w.lat, w.lng),
                  stopover: false,
                });
              }
              vm.waypoints = waypoints;
            });
          }, 500);
        }

        if (this.updateInfo.start_position) {
          setTimeout(() => {
            vm.startLocation = vm.updateInfo.start_position;
            this.setMapBounds();
          }, 1000);
        }
        if (this.updateInfo.end_position) {
          setTimeout(() => {
            vm.endLocation = vm.updateInfo.end_position;
            this.setMapBounds();
          }, 1000);
        }

        if (this.updateInfo.tracked_route.length > 0) this.trackedRoute = this.updateInfo.tracked_route;

        if (this.updateInfo.trip_log.length > 0) {
          let waitingPositions = _.filter(this.updateInfo.trip_log, { type: "waiting_start" });
          for (let i = 0; i < waitingPositions.length; i++) {
            let w = waitingPositions[i];
            this.waitingCircles.push(w.position);
          }
        }

        if (this.updateInfo.defined_route) this.definedRoute = this.updateInfo.defined_route;

        if (this.updateInfo.email_address) this.emailAddress = this.updateInfo.email_address;
        if (this.updateInfo.sms_number) this.smsNumber = this.updateInfo.sms_number;
      }
    },

    reset() {
      this.startLocation = null;
      this.endLocation = null;
      this.startPosition = null;
      this.startMarkerVisible = true;
      this.endMarkerVisible = true;
      this.endPosition = null;
      this.departureTime = null;
      this.maxWaitingTime = 30;
      this.maxStops = 3;
      this.definedRoute = [];
      this.waypoints = [];
      this.savedWaypoints = [];
      this.totalDistance = null;
      this.totalDuration = null;
      this.startAddress = "";
      this.endAddress = "";
      this.expectedArrival = "";
      this.emailAddress = "";
      this.smsNumber = "";
      this.renderDirection = true;
      this.trackedRoute = [];
      this.waitingCircles = [];
      this.infoWindowPos = null;
      this.infoOptions.content = "";
      this.infoWinOpen = false;
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setMapBounds() {
      var vm = this;

      setTimeout(() => {
        vm.$refs.map.$mapPromise.then((map) => {
          const bounds = new vm.google.maps.LatLngBounds();

          if (vm.startLocation) bounds.extend(vm.startLocation);
          if (vm.endLocation) bounds.extend(vm.endLocation);

          map.fitBounds(bounds);
        });
      }, 500);
    },

    showTripReport(log) {
      let logType = "";
      if (log.type == "trip_start") logType = "Resan börjar";
      else if (log.type == "trip_end") logType = "Resan genomförd";
      else if (log.type == "waiting_start") logType = "Väntan började";
      else if (log.type == "waiting_end") logType = "Väntande slut";
      else if (log.type == "on_track") logType = "På rutt";
      else if (log.type == "deviated") logType = "Avvikit från rutt";

      let infoText = `
        ${logType}<br>
        ${this.formatDate(log.time)}
      `;
      if (log.type == "waiting_end") infoText += `<br>${log.duration} min.`;

      this.showInfoWindow(log.position, infoText, true);
      this.zoomPosition(log.position);
    },

    showInfoWindow(position, infoText, show) {
      this.infoWindowPos = position;
      this.infoOptions.content = infoText;
      this.infoWinOpen = show;
    },

    zoomPosition(position) {
      var vm = this;
      setTimeout(() => {
        vm.$refs.map.$mapObject.panTo(position);
        vm.$refs.map.$mapObject.setZoom(13);
      }, 300);
    },

    hide() {
      this.$modal.hide("modal-asset-tracker");
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    // this.debounceDrivingOptions = _.debounce(this.setDrivingOptions, 2000);
  },

  mounted() {
    this.initDate();
  },
};
</script>
