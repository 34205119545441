import { MapElementFactory } from "vue2-google-maps";
import $store from "@/store";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  ctrArgs: (options) => [options],

  events: [],

  mappedProps: {
    options: { type: Object },
    directions: { type: Object, twoWay: true },
  },

  props: {
    origin: { type: [Object, Array] },
    destination: { type: [Object, Array] },
    waypoints: { type: [Object, Array] },
    travelMode: { type: String },
    options: { type: Object },
    // drivingOptions: { type: Object },
  },

  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService();

    this.$watch(
      () => [this.origin, this.destination, this.waypoints, this.travelMode /* this.drivingOptions */],
      () => {
        let { origin, destination, waypoints, travelMode /* drivingOptions */ } = this;
        if (!origin || !destination || !waypoints || !travelMode /* || !drivingOptions */) return;
        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode,
            // drivingOptions
          },
          (response, status) => {
            if (status !== "OK") {
              this.$emit("error_direction", status);
              return;
            }

            let routes = JSON.parse(JSON.stringify(response.routes[0].legs));

            if (routes.length > 0) {
              routes.forEach((route) => {
                delete route.steps;
              });
            }

            $store.commit("SET_OPTIMIZED_PATH", routes);

            directionsRenderer.setPanel(document.getElementById("directionsPanel"));
            directionsRenderer.setDirections(response);
          }
        );
      }
    );
  },
});
