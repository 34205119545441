<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="columns"
          :rows="trips"
          :search-options="{
            enabled: true,
            placeholder: 'Sök',
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <div slot="table-actions">
            <div class="flex flex-wrap">
              <button class="btn-blue mx-1 mt-2 lg:mt-0" @click="createNewTrip()">
                Ny tur
                <BaseIcon icon="plus" class="ml-1" />
              </button>
            </div>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn-blue-outline" @click.prevent="showTrip(props.row)">
                <BaseIcon v-if="props.row.trip_state.tripStarted == false" icon="pencil-alt" />
                <BaseIcon v-else icon="info" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'delete'">
              <button class="btn-red-outline" @click.prevent="deleteTripPrompt(props.row)">
                <BaseIcon icon="trash" class="" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'status'">
              <span v-if="props.row.trip_state.tripStarted == false && props.row.trip_state.tripEnded == false" class="text-secondary-600">Inte startat</span>
              <span v-else-if="props.row.trip_state.tripStarted == true && props.row.trip_state.tripEnded == false" class="text-yellow-500">Pågår</span>
              <span v-else-if="props.row.trip_state.tripStarted == true && props.row.trip_state.tripEnded == true" class="text-accent-500">Avslutad</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <AssetTracker ref="assetTracker" :imei_number="imei" :updateInfo="updateInfo" @routeSaved="getAlarmTrips" />
  </div>
</template>

<script>
import AssetTracker from "@/components/map/AssetTracker";

export default {
  title() {
    return `Tillgångsspårning | SecurCloud`;
  },

  components: {
    AssetTracker,
  },

  data() {
    return {
      imei: this.$route.params.imei,
      user: this.$store.state.user,
      trips: [],
      columns: [
        { label: "Startadress", field: "start_address", sortable: false },
        { label: "Slutadress", field: "end_address", sortable: false },
        { label: "Varaktighet", field: "total_duration", sortable: false, formatFn: this.formatDuration },
        { label: "Avresa kl", field: "departure_time", formatFn: this.formatDate },
        { label: "Datum", field: "createdAt", formatFn: this.formatDate },
        { label: "Status", field: "status" },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ],
      updateInfo: null,
    };
  },

  methods: {
    getAlarmTrips() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/asset-tracker/trip/${this.imei}`)
        .then((response) => {
          this.trips = response.data.trips;

          this.setPageTitle(`Tillgångsspårning (α) - ${response.data.alarm.unit_name}`, "asset_tracker_alarm");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    deleteTripPrompt(trip) {
      this.$modal.show("dialog", {
        title: `<span class="font-normal text-sm">Ta bort resa från <b>${trip.start_address}</b> till <b>${trip.end_address}</b> ?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteTrip(trip._id);
            },
          },
        ],
      });
    },

    async deleteTrip(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/asset-tracker/trip/${_id}/${this.imei}`);

        this.getAlarmTrips();
        this.$modal.hide("dialog");
        this.handleSuccess("Larm har raderats");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    createNewTrip() {
      this.updateInfo = null;
      this.$refs.assetTracker.initDate();
      this.$modal.show("modal-asset-tracker");
    },

    showTrip(trip) {
      var vm = this;
      this.updateInfo = trip;
      this.$nextTick().then(() => {
        vm.$refs.assetTracker.setupTripInfo();
      });
      this.$modal.show("modal-asset-tracker");
    },

    formatDuration(seconds) {
      return this.moment.duration(seconds, "seconds").humanize();
    },

    formatDate(date) {
      return this.moment(date)
        .tz("Europe/Stockholm")
        .format("YYYY-MM-DD, HH:mm");
    },
  },

  created() {
    this.getAlarmTrips();
  },
};
</script>
